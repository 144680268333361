import React, {useEffect} from 'react';
import APIService from "../../services/api-service";
const apiService = new APIService()

const Hide = props => {

    useEffect(() => {

        const hideOrganization = async (e, t) => {
            const result = await apiService.hideOrganizationByEmail(e, t)
            if (result) {
                window.alert('Organization hidden! You need to wait for 5m for sync.')
            }
        }

        const query = new URLSearchParams(window.location.search);
        const email = query.get('email')
        const token = query.get('token')

        if (email && token) {
            hideOrganization(email, token)
        } else {
            window.alert('email or token missed')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div />
    )
}


export default Hide
