import React from "react";
import {AiOutlinePicture} from "react-icons/ai";

const SearchPreviewCard = props => {

    const { item, onClick } = props;
    const { Name, Image, Services } = item;

    return (
        <div className='search-preview-card mr-10 enabledClick' onClick={() => onClick(item)}>
            {Image ? <img className='search-preview-image mr-5' src={Image[0].url} alt='logo' /> : <div className='w-50 h-50 d-flex-center'><AiOutlinePicture color='#CCC' size={32} /></div>}
            <div className='d-flex-1 p-5'>
                {Name && <p className='strong fs-8'>{Name}</p>}
                {Services && <p className='fs-8 mt-3'>{Services.substring(0, 60) + (Services.length > 60 ? '...': '')}</p>}
            </div>
        </div>
    );

}

export default SearchPreviewCard

