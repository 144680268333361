import { LANGUAGE_LOADED, LANGUAGE_SWITCHED } from "../constants/actions";

const languageMain = process.env.REACT_APP_LANGUAGE_MAIN;
const languageAlt = process.env.REACT_APP_LANGUAGE_ALT;
const languageEn = process.env.REACT_APP_LANGUAGE_EN;

const updateLanguage = (state, action) => {

    if (state === undefined) {
        return {
            language: languageMain,
            wording: {}
        };
    }

    switch (action.type) {

        case LANGUAGE_LOADED: {

            const transformedWording = {};
            action.payload.forEach(item => transformedWording[item.key] = {
                [languageMain]: item[languageMain],
                [languageAlt]: item[languageAlt],
                ...(languageEn && {[languageEn]: item[languageEn]}),
            })

            return {
                ...state.language,
                wording: transformedWording,
            }
        }

        case LANGUAGE_SWITCHED:
            return {
                ...state.language,
                language: action.payload,
            };

        default:
            return state.language;
    }
};

export default updateLanguage;
