export const FACEBOOK = 'facebook.com';
export const INSTAGRAM = 'instagram.com';
export const TWITTER = 'twitter.com';
export const LINK = 'link';

const socialNetworks = [FACEBOOK, INSTAGRAM, TWITTER]

const clearUrl = (str) => {
    let url = str;
    url = url.split('http://').join('');
    url = url.split('https://').join('');
    url = url.split('/').join('');
    url = url.split('www.').join('');
    url = url.split(FACEBOOK).join('');
    url = url.split(INSTAGRAM).join('');
    url = url.split(TWITTER).join('');
    return url;
}

export const classifySocialNetwork = str => {
    const url = str.toLowerCase();
    const result = {
        url: str,
        name: str,
        ico: 'link'
    };
    socialNetworks.forEach(sn => {

        if (url.indexOf(sn.toLowerCase()) > -1) {
            const snUrl = url.indexOf('http') > -1 ? url : 'https://' + url;
            result.name = clearUrl(snUrl)
            result.url = snUrl;
            result.ico = sn;

        }
    })
    return result
}
